<template>
  <div>
    <v-container>
      <v-toolbar
        dense
        flat
        color="transparent"
      >
        <v-btn
          icon
          to="Home"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title>{{ $t('xiafen') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          to="Record"
        >
          <v-icon>mdi-clipboard-text-clock</v-icon>
        </v-btn>
      </v-toolbar>

      <v-sheet
        color="nav_bg"
        class="py-3 mt-3 rounded-t-xl"
      >
        <v-list-item>
          <v-list-item-avatar v-if="agentinfo.avatar">
            <v-img :src="require('../assets/img/avatar/'+agentinfo.avatar+'.png')" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ agentinfo.username }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-title>
              <div class="d-flex align-center">
                <img
                  width="16"
                  height="16"
                  src="../assets/img/icons/coin.svg"
                  class="mr-1"
                >
                {{ agentinfo.assets?.available }}
              </div>
            </v-list-item-title>
          </v-list-item-action>
        </v-list-item>
      </v-sheet>
      
      <v-sheet
        color="app_bg"
        class="pa-3 mb-3 rounded-b-xl"
      >
        <v-subheader>
          {{ $t('xiafen_shuliang') }}
        </v-subheader>
        <v-text-field
          v-model.number="amount"
          type="number"
          outlined
          background-color="#fff"
          rounded
          autofocus
          height="56"
          hide-details="auto"
          :placeholder="$t('xiafen_shuliang_shuru')"
        >
          <template v-slot:prepend-inner>
            <img
              width="22"
              height="22"
              src="../assets/img/icons/coin.svg"
            >
          </template>
        </v-text-field>

        <v-textarea
          v-model="remark"
          filled
          rounded
          background-color="#fff"
          :label="$t('beizhu_neirong')"
          hide-details="auto"
          class="mt-3"
        />
      </v-sheet>

      <v-btn
        block
        x-large
        rounded
        color="primary"
        dark
        class="mt-5"
        @click="withdraw"
      >
        {{ $t('tijiao') }}
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import store from "../store/"
  export default {
    data: () => ({
      agentinfo: {},
      amount: '',
      remark: ''
    }),

    computed: {
      
    },
    created() {

    },
    mounted() {
      this.myinfo()
    },
    methods: {
      myinfo() {
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
        }
        this.$server.myinfo(paramObj).then((response) => {
          if(response.code==200){
            this.agentinfo = response.data
            store.commit('agentinfo', this.agentinfo)
          }
        })
      },
      withdraw() {
        if(this.agentinfo.assets.available<this.amount){
          this.$snackbar.warning('Insufficient balance')
          return false
        }
        let paramObj = {
          agent_id: this.getStorage('agent_id'),
          amount: this.amount,
          remark: this.remark,
        }
        this.$server.withdraw(paramObj).then((response) => {
          if(response.code==200){
            this.$snackbar.success(this.$t('chulizhong_shangji'))
            this.myinfo()
            this.amount = ''
            this.remark = ''
          }
        })
      },
      doCopy: function (text) {
        this.$copyText(text).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('copy_ok'))
        }, function (e) {
          console.log(e)
        })
      },
    },
  }
</script>
<style>
.theme--light.v-application {
  background-color: #fff !important;
}
</style>